// choices/roomChoices.js
const roomChoicesEn = [
  { label: "Living Room 🛋️", value: "living-room" },
  { label: "Kitchen 🎛️", value: "kitchen" },
  { label: "Dining Room 🍴", value: "dining-room" },
  { label: "Bedroom 🛏️", value: "bedroom" },
  { label: "Bathroom 🛁", value: "bathroom" },
  { label: "Kids Room 👶", value: "kids-room" },

  { label: "Teen Room 👦", value: "teen-room" },
];

export default roomChoicesEn;
