const bookChoicesEn = [
  {
    label: "Exciting adventures and light enjoyable stories.",
    value:
      'Exciting adventures and light enjoyable stories.{"Red":"3","Yellow":"2"}',
  },
  {
    label: "Dark stories and philosophical, contemplative works.",
    value:
      'Mysteries and dark stories and philosophical, contemplative works.{"Black":"3","Gray":"2"}',
  },

  {
    label: "Inspiring stories of resilience and moving family narratives.",
    value:
      'Inspiring stories of resilience and moving family narratives.{"Green":"3","Brown":"2"}',
  },

  {
    label:
      "Deep, contemplative novels and fantasy stories with magical elements.",
    value:
      'Deep, contemplative novels and fantasy stories with magical elements.{"Blue":"3","Violet":"2"}',
  },
];

export default bookChoicesEn;
