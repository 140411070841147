import React, { useState, useEffect } from "react";
import { answersMap, constructUrl } from "./utility/answersMap";
import Loader from "./utility/Loader";
import { adjustNumbers } from "./utility/adjustResults";
import "./Result.css";
const domainToSend =
  process.env.NODE_ENV === "production"
    ? "airis-chat.vitex.gr"
    : "localhost:3000";
const Result = ({ userData, fordId, lang }) => {
  const [apiResult, setApiResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let formData = answersMap(userData);
      formData.formId = [fordId];
      const body = { formData };
      try {
        const response = await fetch(
          `http${
            process.env.NODE_ENV === "production" ? "s" : ""
          }://${domainToSend}/api/custom/suggestColors`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );

        if (response.ok) {
          const responseClosestColors = await response.json();
          // Adjust the numbers to be within ±1 of the original values
          const closestColors = adjustNumbers(
            responseClosestColors[0],
            responseClosestColors[1],
            responseClosestColors[2]
          );
          setApiResult(closestColors);
          const str = constructUrl(formData);

          const urlParams = new URLSearchParams();
          closestColors.forEach((color, index) => {
            urlParams.append(`code${index}`, color);
          });
          urlParams.append("room", formData.room[0]);
          urlParams.append("style", formData.style[0]);
          urlParams.append("answers", str);
          urlParams.append("lang", lang || "el");
          //Redirect to url with the parameters
          setTimeout(() => {
            window.location.href = `http://${domainToSend}/?${urlParams.toString()}`;
          }, 1000);
        } else {
          setError(
            `API call failed: ${response.status} ${response.statusText}`
          );
        }
      } catch (error) {
        setError(`API call error: ${error.message}`);
      }
    };

    fetchData();
  }, [userData, fordId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!apiResult) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Manrope",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Manrope",
      }}
    >
      <div id="after-loading">
        Έτοιμες οι προτάσεις, θα στα πει η{" "}
        <span className="gradient-text">AIRIS</span>{" "}
      </div>
    </div>
  );
};

export default Result;
