import psyImages from "../../components/utility/psychologicalImageImports";
const gardenChoicesEn = [
  {
    label: "A zen garden with minimalist gray stones and black sand.",

    value:
      'A zen garden with minimalist gray stones and black sand.{"Gray":"3","Black":"2"}',
    imageUrl: psyImages["garden/forFormA.jpg"],
  },

  {
    label: "A vibrant flower garden with red roses and yellow sunflowers.",
    value:
      'A vibrant flower garden with red roses and yellow sunflowers.{"Red":"3","Yellow":"2"}',
    imageUrl: psyImages["garden/forFormB.jpg"],
  },

  {
    label: "A peaceful garden with water, blue lilies, and purple irises.",
    value:
      'A peaceful garden with water, blue lilies, and purple irises.{"Blue":"3","Violet":"2"}',
    imageUrl: psyImages["garden/forFormC.jpg"],
  },

  {
    label: "A lush vegetable garden with green plants and earthy brown soil.",
    value:
      'A lush vegetable garden with green plants and earthy brown soil.{"Green":"3","Brown":"2"}',
    imageUrl: psyImages["garden/forFormD.jpg"],
  },
];

export default gardenChoicesEn;
