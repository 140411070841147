// choices/endChoices.js
const endChoicesEn = [
  {
    label: "Discover your colors",

    value: "results",
  },
  {
    label: "Let's specialize them even more",
    value: "large",
  },
];

export default endChoicesEn;
