// choices/paletteChoices.js
import { images } from "../../components/utility/imageImports";

const paletteChoicesEn = [
  {
    label: "Blue",
    value: "blues",
    imageUrl: images["blues.png"],
  },

  {
    label: "Green",
    value: "greens",
    imageUrl: images["greens.png"],
  },

  {
    label: "Yellow",
    value: "yellows",
    imageUrl: images["yellows.png"],
  },

  {
    label: "Red",
    value: "reds",
    imageUrl: images["reds.png"],
  },

  {
    label: "Orange",
    value: "oranges",
    imageUrl: images["oranges.png"],
  },

  {
    label: "Purple",
    value: "violets",
    imageUrl: images["violets.png"],
  },

  {
    label: "White",
    value: "whites",
    imageUrl: images["whites.png"],
  },

  {
    label: "Gray",
    value: "grays",
    imageUrl: images["grays.png"],
  },

  {
    label: "Brown",
    value: "browns",
    imageUrl: images["browns.png"],
  },
];

export default paletteChoicesEn;
