import psyImages from "../../components/utility/psychologicalImageImports";

const artChoicesEn = [
  {
    label: "Minimalist designs.",
    value:
      'minimalist designs with neutral gray and deep, contrasting black.{"Gray":"3","Black":"2"}',
    imageUrl: psyImages["art/forFormA.jpg"],
  },
  {
    label: "Dynamic and bold abstract art.",
    value:
      'Dynamic and bold abstract art with striking reds and cheerful yellows.{"Red":"3","Yellow":"2"}',
    imageUrl: psyImages["art/forFormB.jpg"],
  },

  {
    label: "Peaceful landscape paintings.",
    value:
      'Peaceful landscape paintings with many blue skies, water, and purple sunsets.{"Blue":"3","Violet":"2"}',
    imageUrl: psyImages["art/forFormC.jpg"],
  },

  {
    label: "Bold, geometric patterns.",
    value:
      'Bold, geometric patterns with steady greens and browns.{"Green":"3","Brown":"2"}',
    imageUrl: psyImages["art/forFormD.jpg"],
  },
];

export default artChoicesEn;
