// choices/lightChoices.js
const lightChoicesEn = [
  {
    label: "Natural light",

    value: "natural",
  },
  {
    label: "Artificial light",
    value: "artificial",
  },

  {
    label: "Mixed lighting",
    value: "mixed",
  },
];

export default lightChoicesEn;
