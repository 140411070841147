// choices/styleChoices.js
import { images } from "../../components/utility/imageImports";

const styleChoicesEn = (room) => [
  {
    label: "Industrial",
    value: "industrial",
    imageUrl: images[`industrial/${room}/forForm.jpg`],
  },

  {
    label: "Scandinavian",
    value: "scandinavian",
    imageUrl: images[`scandinavian/${room}/forForm.jpg`],
  },

  {
    label: "Mediterranean",
    value: "mediterranean",
    imageUrl: images[`mediterranean/${room}/forForm.jpg`],
  },

  {
    label: "Cycladic",
    value: "cycladic",
    imageUrl: images[`cycladic/${room}/forForm.jpg`],
  },

  {
    label: "Neoclassical",
    value: "neoclassical",
    imageUrl: images[`neoclassical/${room}/forForm.jpg`],
  },

  {
    label: "Minimal",
    value: "minimal",
    imageUrl: images[`minimal/${room}/forForm.jpg`],
  },
  {
    label: "Modern",
    value: "modern",
    imageUrl: images[`modern/${room}/forForm.jpg`],
  },
  {
    label: "Boho",
    value: "boho",
    imageUrl: images[`boho/${room}/forForm.jpg`],
  },
  {
    label: "Vintage",
    value: "vintage",
    imageUrl: images[`vintage/${room}/forForm.jpg`],
  },
  {
    label: "Pop",
    value: "pop",
    imageUrl: images[`pop/${room}/forForm.jpg`],
  },
];

export default styleChoicesEn;
