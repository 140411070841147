import React, { useState, useEffect } from "react";
import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { useFormAnswers } from "@quillforms/renderer-core";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./Form.css";
import "../picture-choice";
import logo from "../assets/logos/vitex-logo.png";
import moveToLarge from "../assets/moveToLarge.png";
import Result from "./Result";
import roomChoices from "../formChoices/roomChoices";
import roomChoicesEn from "../formChoices/en/roomChoices";
import styleChoices from "../formChoices/styleChoices";
import styleChoicesEn from "../formChoices/en/styleChoices";
import lightChoices from "../formChoices/lightChoices";
import lightChoicesEn from "../formChoices/en/lightChoices";
import vibeChoices from "../formChoices/vibeChoices";
import vibeChoicesEn from "../formChoices/en/vibeChoices";
import endChoices from "../formChoices/endChoices";
import endChoicesEn from "../formChoices/en/endChoices";
import paletteChoices from "../formChoices/paletteChoices";
import paletteChoicesEn from "../formChoices/en/paletteChoices";
import vacationChoices from "../formChoices/vacationChoices";
import vacationChoicesEn from "../formChoices/en/vacationChoices";
import artCoices from "../formChoices/artChoices";
import artChoicesEn from "../formChoices/en/artChoices";
import weatherChoices from "../formChoices/weatherChoices";
import weatherChoicesEn from "../formChoices/en/weatherChoices";
import bookChoices from "../formChoices/bookChoices";
import bookChoicesEn from "../formChoices/en/bookChoices";
import gardenChoices from "../formChoices/gardenChoices";
import gardenChoicesEn from "../formChoices/en/gardenChoices";
import dayStartChoices from "../formChoices/dayStartChoices";
import dayStartChoicesEn from "../formChoices/en/dayStartChoices";
import { randomStyle, images } from "./utility/imageImports";
import ReactGA from "react-ga4";
import theme from "./utility/theme";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
registerCoreBlocks();

const ShortForm = () => {
  const { t, i18n } = useTranslation("translation");
  const formAnswers = useFormAnswers();
  const [formData, setFormData] = useState(null);
  const [userChoice, setUserChoice] = useState(null);
  const [mirrorLogo, setMirrorLogo] = useState(false);
  const userRoom = useFieldAnswer("room");
  const userStyle = useFieldAnswer("style");
  const end = useFieldAnswer("end");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);
  useEffect(() => {
    if (end && end[0] === "results") {
      ReactGA.event({
        category: "Quiz",
        action: "Completed Small Quiz",
        label: "Completed Small Quiz",
      });
      setTimeout(() => {
        setUserChoice("results");
        setFormData(formAnswers);
      }, 1000);
    }
  }, [end]);
  const handleSubmit = (
    data,
    { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
  ) => {
    setTimeout(() => {
      setIsSubmitting(false);
      completeForm();
      ReactGA.event({
        category: "Quiz",
        action: "Completed Large Quiz",
        label: "Completed Large Quiz",
      });
      setTimeout(() => {
        setUserChoice("largeForm");
        setFormData(formAnswers);
      }, 1000);
    }, 500);
  };
  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      <div className="language-switcher-container">
        <LanguageSwitcher />
      </div>
      {!formData && (
        <div className="logo-container">
          <img
            src={logo}
            alt="Logo"
            className={`fixed-logo ${mirrorLogo ? "right" : "left"}`}
            onClick={() => window.location.reload()}
          />
        </div>
      )}
      {formData && userChoice === "results" ? (
        <Result userData={formData} fordId="1" lang={i18n.language} />
      ) : formData && userChoice === "largeForm" ? (
        <Result userData={formData} fordId="2" lang={i18n.language} />
      ) : (
        <Form
          formId="1"
          beforeGoingNext={({
            setIsFieldValid,
            setIsPending,
            currentBlockId,
            answers,
            setFieldValidationErr,
            setIsCurrentBlockSafeToSwipe,
            goToBlock,
            goNext,
          }) => {
            if (
              currentBlockId === "style" &&
              answers[currentBlockId].value[2] !== undefined
            ) {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Επέλεξε μέχρι 2 στυλ");
              setIsCurrentBlockSafeToSwipe(false);
            } else if (
              currentBlockId === "pallete" &&
              answers[currentBlockId].value[3] !== undefined
            ) {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Επέλεξε μέχρι 3 παλέτες");
              setIsCurrentBlockSafeToSwipe(false);
            } else if (currentBlockId === "room") {
              console.log(answers[currentBlockId].value);
              if (answers[currentBlockId].value === undefined) {
                setIsFieldValid(currentBlockId, false);
                setFieldValidationErr(currentBlockId, "Επέλεξε ένα δωμάτιο");
                setIsCurrentBlockSafeToSwipe(false);
              } else {
                setIsPending(true);
                setTimeout(() => {
                  setIsPending(false);
                  goNext();
                }, 700);
              }
            } else if (currentBlockId === "light") {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
              setMirrorLogo(false);
            } else if (currentBlockId === "furniture") {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
              setMirrorLogo(false);
            } else if (currentBlockId === "end") {
              setIsPending(true);
              setTimeout(() => {
                setIsPending(false);
                goNext();
              }, 600);
            } else {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
            }
          }}
          formObj={{
            messages: {
              "block.defaultThankYouScreen.label": t("form.thankYou"),
              "label.hintText.enter": "↵",
              "label.errorAlert.required": t("form.required"),
              "label.progress.percent": "",
              "label.submitBtn": t("form.results"),
            },
            blocks: [
              {
                name: "multiple-choice",
                id: "room",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: false,
                  label: t("form.room"),
                  choices: i18n.language === "el" ? roomChoices : roomChoicesEn,
                },
              },
              {
                name: "picture-choice",
                classnames: "two-choices",
                id: "style",
                attributes: {
                  label: t("form.style"),
                  description: t("form.max2"),
                  required: true,
                  multiple: true,
                  choices:
                    i18n.language === "el"
                      ? styleChoices(userRoom)
                      : styleChoicesEn(userRoom),
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 2,
                  nextBtnLabel: "OK",
                },
              },
              {
                name: "multiple-choice",
                id: "light",
                attributes: {
                  classnames: "no-ok-button",

                  required: true,
                  multiple: false,
                  verticalAlign: false,
                  label: t("form.light"),
                  choices:
                    i18n.language === "el" ? lightChoices : lightChoicesEn,
                  attachment: isMobile
                    ? null
                    : {
                        type: "image",
                        url:
                          userStyle && userRoom
                            ? images[
                                `${
                                  userStyle[
                                    randomStyle[0] > userStyle.length - 1
                                      ? 0
                                      : randomStyle[0]
                                  ]
                                }/${userRoom}/forDisplay1.jpg`
                              ]
                            : undefined,
                      },
                  layout: "split-right",
                },
              },
              // {
              //   name: "multiple-choice",
              //   id: "furniture",
              //   attributes: {
              //     classnames: "no-ok-button",
              //     required: true,
              //     multiple: false,
              //     verticalAlign: false,
              //     label:
              //       "Έχεις κάποια υπάρχοντα έπιπλα ή διακοσμητικά στοιχεία;",
              //     choices: furnitureChoices,
              //     attachment: isMobile
              //       ? null
              //       : {
              //           type: "image",
              //           url:
              //             userStyle && userRoom
              //               ? images[
              //                   `${
              //                     userStyle[
              //                       randomStyle[1] > userStyle.length - 1
              //                         ? 0
              //                         : randomStyle[1]
              //                     ]
              //                   }/${userRoom}/forDisplay2.jpg`
              //                 ]
              //               : undefined,
              //         },
              //     layout: "split-left",
              //   },
              // },
              {
                name: "multiple-choice",
                id: "vibe",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: false,
                  label: t("form.vibe"),
                  // description: "Θέλω να νιώθω:",
                  choices: i18n.language === "el" ? vibeChoices : vibeChoicesEn,
                  attachment: isMobile
                    ? null
                    : {
                        type: "image",
                        url:
                          userStyle && userRoom
                            ? images[
                                `${
                                  userStyle[
                                    randomStyle[2] > userStyle.length - 1
                                      ? 0
                                      : randomStyle[2]
                                  ]
                                }/${userRoom}/forDisplay1.jpg`
                              ]
                            : undefined,
                      },
                  layout: "split-right",
                },
              },
              {
                name: "picture-choice",
                id: "pallete",
                attributes: {
                  label: t("form.palette"),
                  description: t("form.max3"),
                  required: true,
                  multiple: true,
                  choices:
                    i18n.language === "el" ? paletteChoices : paletteChoicesEn,
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 3,
                  nextBtnLabel: "OK",
                },
              },

              {
                name: "multiple-choice",
                id: "end",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: t("form.end1"),
                  choices: i18n.language === "el" ? endChoices : endChoicesEn,
                  attachment: {
                    type: "image",
                    url: moveToLarge,
                  },
                  layout: "split-right",
                },
              },
              {
                name: "multiple-choice",
                id: "vacation",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: t("form.vacation"),
                  choices:
                    i18n.language === "el"
                      ? vacationChoices
                      : vacationChoicesEn,
                },
              },
              {
                name: "picture-choice",
                id: "art",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: true,
                  verticalAlign: false,
                  label: t("form.art"),
                  choices: i18n.language === "el" ? artCoices : artChoicesEn,
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 1,
                  nextBtnLabel: "OK",
                },
              },
              {
                name: "multiple-choice",
                id: "weather",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: t("form.weather"),
                  choices:
                    i18n.language === "el" ? weatherChoices : weatherChoicesEn,
                },
              },
              {
                name: "multiple-choice",
                id: "book",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: t("form.book"),
                  choices: i18n.language === "el" ? bookChoices : bookChoicesEn,
                },
              },
              // {
              //   name: "multiple-choice",
              //   id: "scenery",
              //   attributes: {
              //     classnames: "no-ok-button",
              //     required: true,
              //     multiple: false,
              //     verticalAlign: true,
              //     label: "Τι είδους τοπίο βρίσκεις πιο όμορφο;",
              //     choices: sceneryChoices,
              //   },
              // },
              {
                name: "picture-choice",
                id: "garden",
                attributes: {
                  required: true,
                  multiple: true,
                  classnames: "no-ok-button",
                  verticalAlign: false,
                  label: t("form.garden"),
                  choices:
                    i18n.language === "el" ? gardenChoices : gardenChoicesEn,
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 1,
                  nextBtnLabel: "OK",
                },
              },
              {
                name: "multiple-choice",
                id: "dayStart",
                attributes: {
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: t("form.dayStart"),
                  choices:
                    i18n.language === "el"
                      ? dayStartChoices
                      : dayStartChoicesEn,
                },
              },
            ],
            settings: {
              animationDirection: "horizontal",
              showQuestionsNumbers: false,
              showLettersOnAnswers: false,
            },
            theme: theme,
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ShortForm;
