// choices/vibeChoices.js
const vibeChoicesEn = [
  {
    label: "Calmness",

    value: "calm",
  },
  {
    label: "Activity",
    value: "active",
  },

  {
    label: "Comfort",
    value: "comfortable",
  },

  {
    label: "Elegance",
    value: "elegant",
  },

  {
    label: "Creativity",
    value: "creative",
  },
];

export default vibeChoicesEn;
