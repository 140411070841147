const dayStartChoicesEn = [
  {
    label: "With a peaceful morning walk by the sea.",
    value: 'With a peaceful morning walk by the sea.{"Blue":"3","Violet":"2"}',
  },

  {
    label: "With an active workout.",
    value: 'With an active workout.{"Red":"3","Yellow":"2"}',
  },

  {
    label: "With a warm cup of coffee in a cozy corner.",
    value:
      'With a warm cup of coffee in a cozy corner.{"Brown":"3","Green":"2"}',
  },

  {
    label: "With a quiet moment of meditation in a minimalist space.",
    value:
      'With a quiet moment of meditation in a minimalist space.{"Gray":"3","Black":"2"}',
  },
];

export default dayStartChoicesEn;
