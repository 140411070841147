const weatherChoicesEn = [
  {
    label: "Steady, mild weather with green landscapes and earthy brown tones.",

    value:
      'Steady, mild weather with green landscapes and earthy brown tones.{"Green":"3","Brown":"2"}',
  },
  {
    label: "Cloudy days with introspective gray and mysterious black clouds.",
    value:
      'Cloudy days with introspective gray and mysterious black clouds.{"Gray":"3","Black":"2"}',
  },

  {
    label: "Calm, clear days with blue skies and magical violet evenings.",
    value:
      'Calm, clear days with blue skies and magical violet evenings.{"Blue":"3","Violet":"2"}',
  },

  {
    label: "Hot, sunny days with bursts of yellow and energetic storms.",
    value:
      'Hot, sunny days with bursts of yellow and energetic storms.{"Red":"3","Yellow":"2"}',
  },
];

export default weatherChoicesEn;
