import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-switcher" style={{ display: "none" }}>
      <select onChange={handleChange} value={i18n.language}>
        <option value="el">EΛ</option>
        <option value="en">EN</option>
        {/* Add more languages here as needed */}
      </select>
    </div>
  );
}

export default LanguageSwitcher;
