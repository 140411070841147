// import psyImages from "../components/utility/psychologicalImageImports";
const vacationChoicesEn = [
  {
    label:
      "A quiet retreat in a minimalist, gray spa with mysterious caves nearby.",

    value:
      'A quiet retreat in a minimalist, gray spa with mysterious caves nearby.{"Gray":"3","Black":"2"}',
    // imageUrl: psyImages["vacation/forFormA.png"],
  },
  {
    label: "A serene beach with azure waters and twilight in the sky.",
    value:
      'A serene beach with azure waters and twilight in the sky.{"Blue":"3","Violet":"2"}',
    // imageUrl: psyImages["vacation/forFormB.png"],
  },

  {
    label: "A lush green forest with tall green trees and earthy brown paths.",
    value:
      'A lush green forest with tall green trees and earthy brown paths.{"Green":"3","Brown":"2"}',
    // imageUrl: psyImages["vacation/forFormC.png"],
  },

  {
    label: "A bustling city with intense nightlife and sunny, open parks.",
    value:
      'A bustling city with intense nightlife and sunny, open parks.{"Red":"3","Yellow":"2"}',
    // imageUrl: psyImages["vacation/forFormD.png"],
  },
];

export default vacationChoicesEn;
